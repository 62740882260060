.sig-slider {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.calc-container {
  min-height: 800px;
  width: 100%;
  max-width: 1200px;
  background-color: white;
}

/* Extra Large slider */

.xlarge_slider {
  width: 100%;
  float: right;
  padding: 0;
  margin-bottom: 10px;
}

.large_slider_label {
  font-family: "Factworks";
  font-size: 1.2em;
  float: right;
  background: none;
  font-weight: bold;
  text-align: right;
}

/* Small slider */

.small_slider {
  float: right;
  position: relative;
  top: 5px;
  left: -20px;
  min-height: 70px;
  width: 100%;
}

.small_slider_label {
  font-family: "Factworks";
  font-size: 1.2em;
  width: 100px;
  font-weight: bold;
  position: relative;
  float: right;
  text-align: right;
  background: none;
}

/* Slider - generic */

.sig-slider {
  float: right;
  position: relative;
  min-height: 70px;
  width: 80%;
  margin-left: 5px;
  margin-right: 15px;
  margin-bottom: 15px;
  bottom: 5px;
}

.slider-label-container {
  text-align: right;
  float: left;
  height: 100%;
  position: relative;
  bottom: 5px;
}

.slider-label {
  position: relative;
  text-align: right;
  align-self: center;
  top: 40px;
}

.slider_value {
  font-size: 2em;
  font-weight: bold;
  position: relative;
  width: 130px;
  margin-right: 5px;
  margin-bottom: 20px;
  top: 15px;
}

.rc-slider-step {
  cursor: pointer;
}

.rc-slider-mark-text {
  color: #999;
  width: 40px;
  margin-right: 200px;
}

.rc-slider-mark {
  background-color: red;
  position: relative;
  right: 50px;
  margin-right: 50px;
}

#prop-ind-a-slider,
#prop-ind-b-slider {
  height: 50%;
}

/* Slider input */

#fake-perc_slider,
#fake-n_slider,
#fake-sig_slider,
#fake-prop-dep-sig-slider,
#fake-prop-dep-a-slider,
#fake-prop-dep-b-slider,
#fake-prop-dep-n-slider,
#fake-prop-ind-sig-slider,
#fake-prop-ind-a-slider,
#fake-prop-ind-b-slider,
#fake-prop-ind-na-slider,
#fake-prop-ind-nb-slider {
  position: relative;
  z-index: 0;
}

#real-perc_slider,
#real-n_slider,
#real-sig_slider,
#real-prop-dep-sig-slider,
#real-prop-dep-a-slider,
#real-prop-dep-b-slider,
#real-prop-dep-n-slider,
#real-prop-ind-sig-slider,
#real-prop-ind-a-slider,
#real-prop-ind-b-slider,
#real-prop-ind-na-slider,
#real-prop-ind-nb-slider {
  position: absolute;
  z-index: 1;
  color: transparent;
  top: 10;
  left: 10;
}

/* Tip container */

.tip_container_xlarge {
  position: relative;
  top: 10px;
  float: left;
  left: 166px;
  width: 220px;
}

/* Colours */

.blue {
  color: #0f41d7;
}

.cyan {
  color: #00abff;
}

.grey {
  color: #465a64;
}

.dark_blue_bg {
  background: #31859c;
}

.light_blue_bg {
  background: #79d2e5;
}

.light_green_bg {
  background: #88b83d;
}

.black_bg {
  background: #222;
}

.grey_bg {
  background: #666;
}

/* Inputs */

input[type="number"],
input[type="text"] {
  background-color: transparent;
  border: 1px dotted transparent;
}

input[type="number"]:focus,
input[type="text"]:focus {
  background-color: transparent;
  border: 1px dotted transparent;
}

input[type="number"]:hover,
input[type="text"]:hover {
  background-color: transparent;
  border: 1px dotted gray;
}

/* P */

.sig-intro-text {
  font-size: 20px;
  line-height: 1.5;
}

.sig-medium-text {
  font-size: 16px;
  line-height: 1.5;
}

.sig-title {
  font-weight: bold;
}

.sig-small-title {
  text-transform: uppercase;
  font-family: "Factworks";
  padding-bottom: 10px;
  margin-bottom: 0px;
  font-weight: bold;
}

.sig-text {
  font-size: 14px;
  line-height: 1.3;
}

/* Prop Ind */

#prop-ind-a {
  border: 2px solid #0f41d7;
  height: 100%;
  min-height: 200px;
}

#prop-ind-b {
  border: 2px solid #00abff;
  height: 100%;
  min-height: 200px;
}

#prop-dep-a {
  border: 2px solid #0f41d7;
  height: 100%;
  min-height: 125px;
}

#prop-dep-b {
  border: 2px solid #00abff;
  height: 100%;
  min-height: 125px;
}

.slider-container {
  border: 2px solid transparent;
  height: 100%;
  min-height: 125px;
}

/* Result */

.r-circle {
  text-align: center;
  margin: 0 auto;
  position: relative;
}

.btn-active {
  background-color: #00abff !important;
}

.btn-inactive {
  background-color: #000028 !important;
  opacity: 0.5 !important;
}

.r-circle-big {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.r-circle-small {
  width: 75px;
  height: 75px;
  position: absolute;
  border-radius: 35px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.r-circle-blue {
  border: solid 2px #0f41d7;
}

.r-circle-green {
  border: solid 2px #00abff;
}

#circle-a-container {
  position: relative;
  float: left;
  width: 100px;
  min-height: 100px;
}

#circle-b-container {
  position: relative;
  float: right;
  width: 100px;
  min-height: 100px;
}

.character_a {
  color: #0f41d7;
  font-weight: bold;
  font-size: 1.2em;
  position: relative;
  margin: 0 auto;
  position: relative;
  top: 40%;
}

.character_b {
  color: #00abff;
  font-weight: bold;
  font-size: 1.2em;
  position: relative;
  margin: 0 auto;
  position: relative;
  top: 40%;
}

.sig-divider {
  border-top: 2px dotted #222;
  width: 90%;
  margin: 0 auto;
}

.sig-value {
  font-weight: bold;
}

.sig-label {
  font-weight: bold;
}

#sig-result-values-left {
  float: right;
}

#sig-result-title {
  text-transform: uppercase;
}

#sig-results-box {
  border: 2px solid #000028;
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

/* a */

.sig-link {
  text-decoration: underline;
  cursor: pointer;
}

/* Tip */

.tip_container {
  position: relative;
  top: 0px;
  left: -82px;
  float: right;
  width: 220px;
}

.tip_container_xlarge {
  position: relative;
  top: 70px;
  left: 220px;
  float: right;
  width: 220px;
}

.tip_container_mobile {
  top: -20px;
  margin-left: 50px;
  position: relative;
  width: 400px;
}

.slider_info_text {
  font-family: "Factworks";
  font-size: 14px;
}

/* Confidence Interval */

#ci_axis_label {
  z-index: 2;
  position: absolute;
  float: right;
  top: 4px;
  left: 22px;
  font-weight: bold;
  font-size: 17px;
  background: #fff;
  border: 1px solid #79d2e5;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 3px;
  padding-bottom: 3px;
}

#ci_text {
  text-align: justify;
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 18px;
  text-align: left;
  line-height: 1.5;
}

#ci_result {
  z-index: 1;
  position: relative;
  float: right;
  text-align: right;
  right: 10px;
  height: 0px;
}

#ci_percentage_result {
  color: #0f41d7;
  font-size: 36px;
  float: right;
  text-align: right;
  margin-right: 12px;
  display: inline;
  position: relative;
  top: 25px;
  font-weight: bold;
}

#ci_header {
  position: relative;
  float: right;
  font-size: 18px;
  font-weight: bold;
  margin-right: 15px;
  width: 200px;
}

#ci_text {
  position: relative;
  text-align: center;
}

.live_value {
  font-weight: bold;
}
