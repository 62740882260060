/* Dragger */

#upload_drag_container {
    border: 1px dashed #d9d9d9;
    background: #fafafa;
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
    position: relative;
    height: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

#upload_drag_container:hover {
  border: 1px dashed #00ABFF;
}

.ant-upload-drag-container {
    width: 100%;
    padding: 15px;
}

span {
  width: 100%;
  height: 100%;
}

.settings {
  color: #314659;
  font-size: 15px;
  line-height: 1.5;
}

#settings-rotation-range-value {
  color: #314659;
  font-size: 13px;
}

.input-group{
}

#upload_drag_container:hover{
  border: 1px dashed #40a9ff;
}

.font-selector {
  padding-bottom: 10px;
}

.rotation-range-slider {
  padding-top: 10px;
}

/* OVerride ANT default colors */

.ant-upload-drag-icon {
  margin: 0 auto;
  position: relative;
}

.ant-upload-text {
  margin: 0;
}

.ant-input-number-input-wrap {
}

.ant-input-number {
  position: relative;
  top: 10px;
}

.ant-input-number-disabled {
  background-color: #e6e6e6;
  color: white;
}

.ant-input-number-input {
  position: relative;
  bottom: 9px;
  left: -15px;
}

.ant-radio-button-wrapper-disabled {
  border-color: #e6e6e6 !important;
}

.ant-input-number:hover {
  border-color: #00ABFF;
}

.rc-slider-handle {
  border: solid 2px #00ABFF;
}

.rc-slider-track {
  background-color: #00ABFF;
}

.rc-slider-dot-active {
  border-color: #00ABFF;
}

.ant-radio-button-wrapper-checked {
  color: #00ABFF;
  border-color: #00ABFF;
}

.ant-radio-button-wrapper:hover {
  color: #00ABFF !important;
}

.anticon {
  color: #00ABFF !important;
}

#settings-rotation-range-value {
  margin-left: 10px;
}

@media (min-width: 1300px){
  .rotation-range-label {
    position: relative;
    bottom: 6px;
  }
  .rotation-points-label {
    position: relative;
    bottom: 10px;
  }
  .min-freq-label {
    position: relative;
    bottom: 10px;
  }
}

.min-freq-label {
  position: relative;
  bottom: 10px;
}
