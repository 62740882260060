@font-face {
  font-family: "Factworks";
  src: url("../fonts/FactworksComplete-Bd.woff2") format("woff2"),
    url("../fonts/FactworksComplete-Bd.eot") format("embedded-opentype"),
    url("../fonts/FactworksComplete-Bd.woff") format("woff"),
    url("../fonts/FactworksComplete-Bd.ttf") format("truetype");
}

@font-face {
  font-family: "Factworks";
  src: url("../fonts/FactworksComplete-Md.woff2") format("woff2"),
    url("../fonts/FactworksComplete-Md.eot") format("embedded-opentype"),
    url("../fonts/FactworksComplete-Md.woff") format("woff"),
    url("../fonts/FactworksComplete-Md.ttf") format("truetype");
}

@font-face {
  font-family: "Factworks";
  src: url("../fonts/FactworksComplete-Rg.woff2") format("woff2"),
    url("../fonts/FactworksComplete-Rg.eot") format("embedded-opentype"),
    url("../fonts/FactworksComplete-Rg.woff") format("woff"),
    url("../fonts/FactworksComplete-Rg.ttf") format("truetype");
}

body {
  overflow-y: initial !important;
  overflow-x: initial !important;
  margin: 0;
  font-family: "Factworks" !important;
  padding: 0 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100% !important;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  background-image: url("../img/BG-dark.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

#callback-wheel {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 100px;
  margin-top: 40%;
}

#root,
.full-height {
  min-height: 100vh;
}

.burger-menu-icon {
  position: relative;
  top: 5px;
  left: 5px;
}

.main-margins {
  margin-left: 5% !important;
  margin-right: 5% !important;
}

@media (max-width: 500px) {
  .main-margins {
    margin-left: 2% !important;
    margin-right: 2% !important;
  }
}

.max-width-all {
  max-width: 2200px;
}

.header-open-app {
  border-bottom: 3px solid #00abff;
}

.header-closed-app {
  border-bottom: 3px solid transparent;
}

.translator-download {
  color: #000028;
  font-size: 20px;
}

.react-select span {
  height: 2%;
}

.value-container div {
  position: absolute;
  white-space: nowrap;
}

.preview-area [class^="Title"] {
  color: #000028 !important;
}

.overlay {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9998;
  position: fixed;
}

.login-container {
  width: 80%;
  margin: 0 auto;
  position: relative;
  top: 200px;
}

#fw-logo {
  margin: 0 auto;
  display: block;
}

.fw-login-card {
  background-color: white;
  border-radius: 5px;
  max-width: 500px;
  width: 90%;
  margin: 0 auto;
}

.t-chevron {
  opacity: 1 !important;
}

.fw-logo {
  height: 40px !important;
  margin-top: 7px;
  margin-left: 10px;
}

.color-menu {
  width: calc(100% - 29px);
}

.flex-1 {
  flex-grow: 1;
  flex-shrink: 0;
}

.home-apps {
  align-self: flex-start;
}

.footer-logo-sizing {
  width: 45px;
  height: 45px !important;
}

.full-footer {
  background-color: #000028;
  color: white !important;
  bottom: 0;
  padding-top: 80px;
  padding-bottom: 80px;
  max-height: 300px;
  align-self: flex-end !important;
}

.footer {
  background-color: #000028;
  color: white !important;
  bottom: 0;
  align-self: flex-end !important;
}

.footer .inner {
  padding-left: 40px;
  padding-right: 40px;
  max-width: 1260px;
  white-space: nowrap;
}

.footer .section-1 {
  max-width: 8.33% !important;
}

.footer .section-2,
.footer .section-3 {
  padding-left: 10px;
  padding-right: 10px;
}

.footer .darker {
  color: #60609c !important;
}

@media (max-width: 1280px) {
  .menu-bar .responsive-align {
    justify: center;
  }
}

.width-fit {
  width: fit-content;
}

@media (max-width: 767px) {
  .footer {
    text-align: center;
    background-color: #000028;
    color: white !important;
    bottom: 0;
    max-height: none;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .footer .section-1 {
    margin-bottom: 40px !important;
    max-width: none !important;
  }

  .footer .section-2 {
    margin-bottom: 40px;
  }

  .footer .section-4 {
    margin-top: 40px;
  }

  .section-4 .float-right {
    float: none !important;
  }

  .footer ul {
    padding: 0px;
  }
}

.footer-margin {
  margin-right: 15% !important;
  margin-left: 15% !important;
}

.footer-share-sizing,
.footer-share-sizing:hover {
  height: 30px;
  margin-left: 10px;
  color: inherit !important;
}

.footer ul {
  padding: 0px;
  list-style-type: none;
}

.footer li {
  line-height: 28px;
}

.burger-menu-icon {
  color: white;
  height: 40px !important;
  width: 40px !important;
}

.burger-menu {
  height: 100%;
  background-color: #000028 !important;
}

.burger-menu b {
  font-size: 1.3em !important;
}

.footer a:hover,
.footer a,
.menu-bar a,
.menu-bar a:hover,
.home a:hover,
.home-apps a:hover,
.clean-button a {
  text-decoration: none !important;
  color: inherit !important;
}

.clean-button:focus,
.clean-button span:focus,
.clean-button b:focus,
.burger-menu:focus {
  outline: none;
  border: none;
}

.menu-bar {
  min-height: 70px;
  background: transparent;
}

.home-card,
.login-card {
  background-color: white;
  opacity: 1;
  position: relative;
  border-color: transparent;
}

.home-card:hover .hc-overlay {
  opacity: 0.3;
}

.hc-overlay {
  mix-blend-mode: multiply;
  transition: 0.4s ease;
  opacity: 0;
  position: absolute;
  background-color: #82c8f0;
}

.login-card {
  background-color: white;
  min-width: 455px;
}

.home-icon-sizing {
  height: 60px;
}

.app-switcher {
  text-transform: capitalize !important;
  color: white !important;
  white-space: nowrap;
  font-size: 20px;
}

.app-switcher-text {
  font-size: 16px !important;
}

.app-switcher:focus {
  border: none !important;
  outline: 0 !important;
}

.home-icon-label {
  background-color: #00abff;
  border-color: transparent;
  color: white;
  font-weight: bold;
  font-size: 20px;
}

.loader {
  animation: spin 2s linear infinite;
}

.formcontrol-cheat {
  left: -20px;
}

.radio-label {
  white-space: nowrap !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.job-bg {
  background-color: rgba(255, 255, 255, 1);
  color: black;
  opacity: 1;
  z-index: 9999;
}

.job-id,
.text-highlight {
  color: #00abff;
  font-weight: bold;
}

.send-icon:hover,
.clickable:hover {
  cursor: pointer;
  text-decoration: none !important;
  background: none !important;
  border: none !important;
}

.clickable {
  text-decoration: none !important;
  background: none !important;
  border: none !important;
}

.send-icon-disabled {
  opacity: 0.3;
}

.preview-area:hover {
  cursor: pointer;
}

.drop-zone {
  background-color: white;
  cursor: pointer;
}

.drop-zone-text,
.login-text {
  font-size: 1.8em !important;
  text-align: center;
}

.drop-zone-text-small {
  font-size: 1.3em !important;
}

@media (max-width: 500px) {
  .drop-zone-text {
    font-size: 1.2em !important;
  }
  .drop-zone-text-small {
    font-size: 1em !important;
  }
}

.file-input-area:hover {
  opacity: 0.7;
}

.hidden-indicator {
  z-index: 5000 !important;
  position: absolute;
  top: 5.1em;
  color: #00abff;
}

.circle-indicator {
  z-index: 6000 !important;
  position: absolute;
  overflow: visible !important;
  width: 1px;
}

.sheet-indicator {
  color: #00abff;
  font-weight: bold;
}

.hidden-indicator-right {
  left: 4em;
}

.hidden-indicator-left {
  left: 1.6em;
}

.t-chevron {
  width: 80px;
  cursor: pointer;
}

@media (max-width: 500px) {
  .t-chevron {
    width: 50px;
  }

  .hidden-indicator-left {
    top: 10px !important;
    left: 45px;
  }

  .hidden-indicator-right {
    top: 10px !important;
    left: 5px;
  }
}

.home-title {
  font-weight: bold;
}

.medium-text {
  font-size: 20px;
}

.t-chevron-left {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.t-outer-chevron:hover {
  opacity: 0.7;
}

.error-text {
  color: #ae3205 !important;
}

.logout-button {
  background: transparent;
  border: none;
}

.logout-button:hover {
  cursor: pointer;
}

.anticon {
  position: relative;
}

.btn-default {
  background-color: lightgray !important;
}
