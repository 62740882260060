.main-bg {
  background-color: #fafafb;
}

.split-icon {
  cursor: pointer;
}

.auto-width {
  width: auto !important;
}

.wc-side-tabs {
  background-color: white;
}

.drag-handle {
  display: none;
}

.react-grid-Container {
  width: 100%;
  height: 100%;
}

.settings .ant-btn:nth-child(2) {
  width: 100% !important;
}

.fw-footer {
  background-color: #0f41d7;
  color: white;
}

.react-grid-HeaderCell .react-grid-checkbox-container {
  display: none;
}

.react-grid-HeaderCell .form-group{
  position: relative;
  z-index: 5;
  left: -100px !important;
  display: flex;
  width: 20em;
}

.tooltip-bg {
  background-color: #000028 !important;
}

.displayedRowBg {
  background: #edfaff !important;
}

.bg-white {
  background-color: white !important;
}

.handles-outer {
  background-color: rgba(0, 0, 0, 0.0);
  z-index: 5;
  left:0;
}

.handles-outer.collapsed {
  position:absolute;
  width: 100%
}

.react-grid-HeaderCell .form-control {
  height: 2em;
}

.handles {
  position: relative;
  left: -1px;
  top: 50px;
  margin-top: 10px;
  width: 41px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.handles img {
  height: 24px;
}

.dropdown-right-btn {
  border-top-left-radius: 0px!important;
  border-bottom-left-radius: 0px!important;
  text-align: left;
}

.dropdown-left-btn, .dropdown-left-btn:hover {
  position:relative;
  width: 80px;
  text-align: center;
  cursor:default;
  color: #495057;
  background-color: #e9ecef;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  right: -1px;
}

.handles-inner {
  position:absolute;
  z-index:5;
}

.overflow-auto {
  overflow:auto;
}

.outer-wc {
  height: 85vh;
  min-height: 800px;
}

@media (max-width: 1300px) {
  .handles {
    left: 0;
    position: relative;
    width: 41px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    z-index:5;
  }

  .handles-inner {
    position:absolute;
    top: 70px;
  }

  .handles-open {
    width:calc(33% + 1em);
    min-width: 350px;
    height:10px;
  }

  .handles-open .handles {
    float:right;
    clear:right;
    left:40px;
  }

  .wc-side-tabs {
    background-color: white;
    width: calc(33% + 1em);
    min-width: 350px;
    height: calc(85vh - 32px);
    min-height:768px;
    top:186px;
    left: 0;
    margin-top: 16px;
    z-index: 2;
    position: absolute;
  }

}

.main-header {
  padding: 1em;
  background-color: white;
}

.rc-slider-mark-text {
  width:auto !important;
  margin:auto !important;
}

.drawer-content-wrapper {
  position: fixed;
  padding-right:0px;
  margin-top: 16px;
  background: rgba(255, 255, 255, 0.4) !important;
  border-radius: 5px 0 0 5px;
  box-shadow: none !important;
  border-color: rgb(222, 226, 230);;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
}

 .drawer-content {
   overflow:hidden !important;
 }

.forward {
  z-index: 9999 !important;
}

 .drawer-content::before {
  content: '';
  opacity: .9;
  position:absolute;
  height:200%;
  width:200%;
  background: white !important;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  z-index:1 !important;
}

.drawer-content: {
  z-index: 9999 !important;
}

.drawer-right {
  box-shadow: none !important;
  border-color: rgb(222, 226, 230);
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  border-right: none;
}

.drawer {
  z-index: 9000 !important;
}

.settings-max-amount, .settings-min-freq {
  max-width: 5em;
  max-height: 1.75em;
}

.rotation-points-label {
  position: relative;
  bottom: 10px;
}

@import url('https://fonts.googleapis.com/css?family=Fredoka+One|Leckerli+One|Lobster|Molle:400i|Oleo+Script|Pacifico|Share+Tech+Mono|Skranji|Montserrat|Nanum+Gothic|Open+Sans|Crimson+Text');

font-family: 'Lobster', cursive;
font-family: 'Oleo Script', cursive;
font-family: 'Share Tech Mono', monospace;
font-family: 'Fredoka One', cursive;
font-family: 'Skranji', cursive;
font-family: 'Pacifico', cursive;
font-family: 'Leckerli One', cursive;
font-family: 'Molle', cursive;
font-family: 'Montserrat', cursive;
font-family: 'Nanum Gothic', cursive;
font-family: 'Open Sans', cursive;
font-family: 'Crimson Text', cursive;

#upload_drag_container {
    background: black !important;
}

.filename {
  font-family: courier;
  font-size: 14px;
  margin-bottom: 10px;
}

#select-file-input {
  margin: 0 auto;
  margin-top: 150px;
  width: 50%;
  height: 250px;
}

#preview-file {
  margin: 0 auto;
  margin: 5px;
  padding-top: 20px;
}

.chevron {
  position: relative;
  top: 50px;
  left: -50px;
  opacity: 0.2;
}

#wc-preview-header{
  background: red;
}

#wc-help-text {
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  margin-left: 10px;
}

.wc-displayedRowBg{
  background: #eff5ff !important;
}

.cloud-area{
  position: relative;
}

.wc-drawer {
  margin-top: 57px;
}

.react-grid-Cell {
  box-shadow: none !important;
}

.react-grid-HeaderCell {
  background-color: white !important;
  border-left: none !important;
  border-right: none !important;
  box-shadow: none !important;
}

.wc-control-panel {
  align-self: flex-end;
  }

.drawer-left.drawer-open {
  width: 1px;
}

.btn-light {
  border-color: #e5e5e5 !important;
}

#linear-cloud {
  position: relative;
  top: 50px;
  margin-top: 35px;
  margin-left: 5vw;
  width: 65vw;
}

.wc-linear-word {
  width: initial;
  margin: 0px;
  padding: 0px;
}

.wc-rect-button, .wc-arch-button {
  width: 95px;
}

.react-grid-Container {
    width: 100% !important;
}

.ant-upload-drag-container {
    padding: 0px !important;
}
